import { graphql } from 'gatsby'
import get from 'lodash/get'
import React, { Component } from 'react'
import AdSense from 'react-adsense'

import { withStyles } from '@material-ui/core/styles'

import Layout from '../../components/layout'
import Pagenation from '../../components/molecules/pagenation'
import ShareButtons from '../../components/molecules/share'
import BlogList from '../../components/organisms/blogList'
import BlogCategory from '../../models/blogCategory/blogCategory'
import { AllBlogPost } from '../../models/blogPost/blogPost'
import { ArticleGoogleStructuredConfig } from '../../models/seoConfig/ArticleGoogleStructure'
import SEOConfig from '../../models/seoConfig/seoConfig'
import PageType from '../../models/siteConfig/pageType'
import SiteConfig from '../../models/siteConfig/siteConfig'

const styles = {
  title: {
    textAlign: 'center',
    margin: '20px 0'
  }
}

class BlogCategoryTemplate extends Component {
  render() {
    const pageContext = get(this, 'props.pageContext')
    const blogCategories: BlogCategory[] = get(this, 'props.data.allContentfulBlogPostCategory.nodes')
    const blogPosts: AllBlogPost[] = get(this, 'props.data.allContentfulBlogPost.edges')
    const siteConfig: SiteConfig = get(this, 'props.data.site.siteMetadata.siteConfig')
    const classes = get(this, 'props.classes')
    const seoConfig = new SEOConfig(PageType.Category, pageContext.categoryName, null, null, siteConfig, siteConfig.baseURL + '/' + siteConfig.siteImageSrc, pageContext.categorySlug)
    const articleStructured: ArticleGoogleStructuredConfig = {}
    const pagenation = {
      pageCount: pageContext.pageCount,
      currentPage: pageContext.currentPage,
      baseUrl: pageContext.baseUrl
    }
    return (
      <Layout seoConfig={seoConfig} blogCategories={blogCategories} articleStructured={articleStructured}>
        <h1 className={classes.title}>{pageContext.categoryName}</h1>
        <ShareButtons url={seoConfig.ogpURL()}></ShareButtons>
        <Pagenation pagenation={pagenation} />
        <BlogList blogPosts={blogPosts}></BlogList>
        <Pagenation pagenation={pagenation} />
        <ShareButtons url={seoConfig.ogpURL()}></ShareButtons>
        <div>
          <AdSense.Google
            style={{ display: 'block' }}
            client='ca-pub-5524131539322102'
            slot='4430167272'
            format='auto'
            responsive='true'
          />
        </div>
      </Layout >
    )
  }
}

export default withStyles(styles)(BlogCategoryTemplate)

export const blogPost = graphql`
query blogCategoryQuery($skip: Int!, $limit: Int!,$categorySlug: String!) {
  site {
    siteMetadata {
      siteConfig {
        title
        description
        siteImageSrc
        baseURL
        author {
          name
          nameCall
          bio
          medias {
            name
            link
            viewBox
            paths {
              d
              fill
            }
          }
        }
      }
    }
  }
  allContentfulBlogPostCategory {
    totalCount
    nodes {
      name
      slug
    }
  }
  allContentfulBlogPost(
    filter: {categories: {elemMatch: {slug: {eq: $categorySlug}}}},
    sort: {fields: [createdAt],order: DESC},
    limit: $limit,
    skip: $skip
  ) {
    edges {
      node {
        title
        slug
        description{
          description
        }
        categories {
          name
          slug
        }
        heroImage{
          file{
            url
          }
          fixed(width:1200,height:630){
            ...GatsbyContentfulFixed_withWebp
          }
          fluid(maxWidth:720, quality:30){
            ...GatsbyContentfulFluid_withWebp
          }
        }
        content {
          childMarkdownRemark {
            excerpt(pruneLength: 99999)
            html
          }
        }
        affiliates {
          amazonLink
          amazonImage {
            amazonImage
          }
          rakutenLink {
            rakutenLink
          }
          name
        }
        createdAt(formatString: "記事投稿日 YYYY年 MM月 DD日")
        updatedAt(formatString: "最終更新日 YYYY年 MM月 DD日")
        vlog
      }
    }
  }
}
`
